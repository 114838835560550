@font-face {
  font-family: "Microsoft YaHei";
  src: url("./assets/fonts/Microsoft\ YaHei.eot");
  src: url("./assets/fonts/Microsoft\ YaHei.eot") format("embedded-opentype"),
    url("./assets/fonts/Microsoft\ YaHei.woff2") format("woff2"),
    url("./assets/fonts/Microsoft\ YaHei.woff") format("woff"),
    url("./assets/fonts/Microsoft\ YaHei.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

body {
  margin: 0 !important;
  font-family: "Microsoft YaHei" !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
:root {
  --button-color: #e9e9e9;
  --button-color-2: #cecece;
  --button-color-3: #5162bb;
  --primery-color: #3c4572;
  --secondry-color: #5563a7;
  --box-color: rgba(194, 200, 232, 0.6);
  --back-color: #e9ecfb;
}

.verify-mobile {
  transition: all 1s ease-in;
  width: 90%;
  padding: 15px;
  height: 10px;
  border-radius: 20px;
  border: none;
  background: #f1f1f1;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.verify-btn {
  width: 95% !important;
  padding: 15px;
  border-radius: 20px;
  border: none;
  background: #ebd788;
  margin-bottom: 20px;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5162bb;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5162bb;
  border-radius: 20px;
}

.wallet-manage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.6);
  /*width: 85%;*/
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 40px 20px;
  box-sizing: border-box;
  transition: all 0.4s ease;
  /*height: 250px;
     */
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.wallet-manage .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 0 0 25px;
}

.wallet-manage .content * {
  margin: 0;
}

.wallet-manage .content .head {
  letter-spacing: 0.67px;
  text-align: left;
  font-size: 8px;
  color: #9a9a9a;
  width: 180px;
  margin-bottom: 5px;
}

.wallet-manage .content .add {
  position: relative;
}

.wallet-manage .content .add > button {
  position: absolute;
  bottom: 100%;
  left: 80%;
  border-radius: 50%;
  background-color: #5162bb;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border: none;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
}

.wallet-manage .content .add .balance {
  letter-spacing: 2.56px;
  text-align: left;
  color: #000000;
  border-bottom: solid 1px #707070;
  width: 180px;
  margin-bottom: 10px;
  font-size: 22px;
}

.wallet-manage .content .saving {
  letter-spacing: 0.81px;
  text-align: left;
  color: #3a3939;
  font-size: 10px;
  width: 180px;
  margin-top: 15px;
}

.wallet-manage .actions {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.wallet-manage .actions .resive,
.wallet-manage .actions .send {
  padding: 16px 64px;
  font-size: 16px;
  border: none;
  font-weight: 600;
  margin: 20px;
}

.wallet-manage .actions .send {
  border-radius: 20px;
  width: 50%;
  background-color: #5162bb;
  color: #fffbfb;
  box-sizing: border-box;
}

.wallet-manage .actions .resive {
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.479);
}

@media screen and (max-width: 500px) {
  .wallet-manage {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .wallet-manage .content {
    padding: 0;
  }
  .wallet-manage .actions .resive,
  .wallet-manage .actions .send {
    width: 80%;
    padding: 10px 0;
    margin: 1.25rem 0;
  }
}

@media screen and (max-width: 411px) {
  .wallet-manage .actions {
    flex: 1;
  }

  .wallet-manage .content {
    flex: 2;
  }
  .wallet-manage .actions .resive,
  .wallet-manage .actions .send {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .wallet-manage {
    flex-direction: column;
    padding-bottom: 0;
  }
  .wallet-manage .actions {
    width: 100%;
  }
}


.minContainer{
  margin: 0 auto;
  width: 100vw;
  max-width: 1700px;
  display: flex;
}
.layoutContainer {
  background-image: url("../../../assets/back.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
background-attachment: fixed;
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  /* overflow: hidden; */
}
.layoutContainer .sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  min-height: calc(100vh - 40px) !important;
}
@media screen and (max-width: 786px) {
  .container {
    width: 100%;
  }
  .layoutContainer .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    min-height: fit-content !important;
  }
  .layoutContainer {
    overflow: hidden;
  }
}
.contents {
  margin-left: 20px;
  width: 100%;
}
#back-to-top-anchor{
  width: 0;
  height: 0;
}



.finance {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    background-color: #d1d6ef;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.finance .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.finance .title h4 {
    flex-grow: 0;
    color: #9a9a9a;
    font-size: 16px;
    font-weight: 900;
}

.finance .commissions {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-template-rows: auto;
    grid-gap: 10px;
    margin: 0 auto;
}

.finance .commissions button {
    width: 100%;
    cursor: pointer;
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.finance .commissions button .percent {
    border-radius: 50%;
    display: flex;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 900;
    color: var(--button-color);
    background-color: var(--button-color-3);
    margin: 0 auto;
    box-shadow: 0 4px 15px #525d97;
    margin-bottom: 24px;
}

.finance .commissions button span {
    display: block;
    font-size: 16px;
    font-weight: 900;
    color: var(--secondry-color);
    margin: auto 0;
    text-align: center;
    word-break: break-all;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.actions button {
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 900;
    background-color: var(--secondry-color);
    color: var(--button-color);
    margin: 0 4px;
    border-radius: 10px;
}

.actions button:first-child {
    height: 48px;
    margin-left: 0;
}

.actions button:last-child {
    height: 40px;
    margin-right: 0;
}

.upgrade {
    margin: 4px 0 0 0;
    width: 100%;
    text-align: end;
}

.upgrade button {
    background-color: transparent;
    border: none;
    color: #9a9a9a;
    font-size: 14px;
    font-weight: 900;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin: 16px 0 48px 0;
}

.input-group label {
    display: flex;
    justify-content: start;
    align-items: center;
}

.input-group label span {
    display: block;
    margin: 0 36px 0 8px;
    font-size: 16px;
    font-weight: 900;
    color: var(--primery-color);
}

.input-group label>button {
	border-radius: 50%;
	background-color: #5162bb;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	border: none;
	width: 20px;
	height: 20px;
	box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
}

.input-group .input-icon {
    margin: 12px 0 0 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-group .input-icon input, .input-group .input-icon span {
    max-width: 100%;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid var(--primery-color);
    color: var(--primery-color);
    font-size: 18px;
}

.input-group .input-icon span {
    width: 36px;
    height: 38px;
}

.input-group .input-icon input {
    height: 36px;
    width: calc(100% - 36px);
}

.color-gold {
    color: #CFB53B !important;
}

.font-sm {
    font-size: 12px;
    margin: 0;
}
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  user-select: auto;
  cursor: default;
}
p.title {
  font-size: 40px;
  color: #3c4572;
  font-weight: 600;
  margin: 20px;
  align-self: baseline;
}
.mainContainer {
  flex-basis: 100%;

}
.mainContent {
  border-radius: 20px;
  background-color: #d1d6ef;
  padding: 36px 20px;
  box-shadow: -7px -7px 12px 0 rgba(0, 0, 0, 0.16);
  margin-right: 20px;
}
.mainContent > div {
  overflow-x: scroll;
}

.title-header,
.body-content {
  display: flex;
  justify-content: space-around;
}
.body-content {
  background-color: #e9ecfb;
  height: 40px;
  border-radius: 10px;
  line-height: 40px;
  margin: 10px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.body-content span {
  color: #3c4572;
}
.body-content span a {
  color: #3c4572;
  text-decoration: none;
}
.body-content span,
.title-header span {
  text-align: left;
  width: 27%;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.not-gen {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-top: 50px;
}
.title-header span:first-child,
.body-content span:first-child {
  text-indent: 20px;
  width: 10%;
}
.title-header span:not(:first-child) {
  color: #7b7b7b;
}
.title-header span:first-child {
  color: #3c4572;
}
.body-content span:last-child,
.title-header span:last-child {
  width: 10%;
}
.body-content span svg {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: red !important;
  position: relative;
  cursor: pointer;
  top: 10px;
}
.textContainer {
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 20px 0;
  box-sizing: border-box;
  width: calc(100% - 20px);
  right: 0px;
}
.textContainer .caption {
  border-radius: 22px;
  /*box-shadow: -7px -7px 12px 0 rgb(0 0 0 / 16%);
  */
  padding: 1px 112px 1px 20px;
  background-color: #d1d6ef;
  line-height: 30px;
  border-radius: 22px;
  box-shadow: -14px -14px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #d1d6ef;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  -ms-border-radius: 22px;
  -o-border-radius: 22px;
}
.textContainer .caption p,
.textContainer .caption span {
  color: #3c4572;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.98px;
}
.textContainer .caption p {
}
.textContainer .circleTitle {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: #b0b5ce;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.textContainer .circleTitle p {
  white-space: nowrap;
  line-height: 0px;
  color: #3c4572;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.textContainer .circleTitle p:first-child {
  font-size: 22px;
  font-weight: 600;
  margin-top: 4px;
}
.textContainer .circleTitle p:last-child {
  margin-top: 2px;
}
@media screen and (max-width: 786px) {
  .mainContent {
    margin: 0 auto !important;
    max-width: 90vw;
  }
  .mainContent.wallet {
    margin-top: 70px !important;
    margin-bottom: 30px !important;
  }
  p.title {
    margin-top: 60px;
  }
  .textContainer {
    margin-left: 20px;
    width: calc(100% - 40px);
  }
  .body-content span a {
    margin-right: 50px;
  }
  .title-header span:nth-child(4),
  .body-content span:nth-child(4) {
    position: relative;
    left: 20px;
  }
  .body-content span svg {
    left: 10px;
  }
}
@media screen and (max-width: 760px) {
  .tbl-scroll {
    min-width: 700px;
  }
}
.chats {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}
.chats .chat-item {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 1px 2px 19px 0px #00000052;
  border-radius: 17px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 19px;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 13px;
}
.chats .chat-item p {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#ShowChats {
  width: 100%;
  height: 100%;
  transition: all 2s ease-in;
}

/* @media screen and (max-width: 992px) {
    .chats .chat-item p{
        max-width: 50%;
     
    }} */

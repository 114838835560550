.aboutus {
  display: flex;
  justify-content: flex-end;
}

p.title.about {
  text-transform: uppercase;
}

.horizentalTitle {
  text-orientation: sideways;
  -webkit-text-orientation: sideways;
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
  color: #3c4572;
  font-weight: 900;
  opacity: 0.33;
  letter-spacing: 21px;
  font-size: 40px;
  line-height: 7px;
  margin-bottom: 20px;
  margin-right: -26px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.aboutContent {
  box-shadow: -14px -14px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #b0b5ce;
  border-radius: 50px 0 0 0;
  -webkit-border-radius: 50px 0 0 0;
  -moz-border-radius: 50px 0 0 0;
  -ms-border-radius: 50px 0 0 0;
  -o-border-radius: 50px 0 0 0;
  width: 70%;
  margin: 20px;
  padding: 17px 30px;
  margin-top: -15px;
}
.aboutContent p {
  line-height: 26px;
  text-shadow: #3c4572;
}
.aboutImage {
  margin-top: -50px;
  display: flex;
  margin-right: 20px;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.aboutImage img {
  width: 185px;
  object-fit: cover;
}
@media only screen and (max-width: 768px) {
  .aboutus {
    justify-content: center;
  }
  .aboutContent {
   
    padding: 17px 10px 17px 15px;
    margin-right: 0;
 
  }
}

.mainContainer.home * {
  box-sizing: border-box;
}

.mainContainer.home {
  display: flex;
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home .center-container {
  margin-right: 10px;
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home .center-container .search-container {
  border-radius: 30px;
  height: 50px;
  width: 100%;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.6);
  -o-border-radius: 30px;
  display: flex;
}

.home .center-container .search-container input {
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  background-color: transparent;
  border: none;
  color: #3c4572;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
}

.home .center-container .search-container input::placeholder {
  color: #b2b7d2;
  font-size: 15px;
  font-weight: 600;
}

.home .center-container .search-container span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
}

.home .center-container .search-container span path {
  fill: #b2b7d2;
}

.home .center-container .search-container input:focus ~ span path {
  color: #3c4572;
}

input:focus {
  outline: none;
}


.payment-methodes {
  border-radius: 30px;
  background-color: rgba(194, 200, 232);
  background-repeat: round;
  background-size: cover;
  width: 100%;
  max-width: 858px;
  margin-top: 15px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 20px;
  display: grid;
  grid-template-columns: 2.5fr 9.5fr;
  grid-template-rows: auto;
  grid-gap: 15px;
  min-height: 200px;
  height: auto;
  box-sizing: border-box;
}
.payment-methodes * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Microsoft Tai Le";
  font-weight: bold;
}

.payment-methodes>div {
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: 40px auto;
  grid-gap: 15px;
}

.payment-methodes h2 {
  width: 100%;
  text-align: left;
  font-size: 24px;
  color: #3c4572;
  user-select: none;
  font-weight: 800;
  text-align: center;
  margin: auto;
}

.payment-methodes img {
  width: 170px;
  margin: auto;
}
.payment-methodes .address {
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-methodes .address button {
  padding: 6px 15px;
  background-color: #4b5aa7;
  border: none;
  height: 100%;
  font-size: 12px;
  min-width: fit-content;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #4b5aa7;
}
.payment-methodes .icon-copy {
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.payment-methodes .icon-copy img {
  width: 18px;
}
.payment-methodes .address span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.payment-methodes .address p {
  width: 100%;
  color: #4b5aa7;
  padding: 6px 15px 7px 25px;
  overflow-x: hidden;
  font-size: 11px;
  border: 1px solid #4b5aa7;
  margin-left: -10px;
  text-align: center;
  border-radius: 0 8px 8px 0;
  max-width: 473px;
  text-overflow: ellipsis;
}
.payment-methodes .box-items {
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-template-rows: min-content min-content;
  grid-gap: 15px;
  margin: auto 0;
}
.payment-methodes .item {
  padding: 10px;
  background-color: #b3bdef99;
  box-shadow: 5px 5px 5px rgba(172, 172, 172, 0.4);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  color: #3c4572;
  font-size: 11px;
}
.payment-methodes .item span {
  border: 1px dashed #3c4572;
  text-align: center;
  padding: 5px;
  border-image: url("./img/border.svg") 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 10px;
  word-break: break-all;
}
.payment-methodes .btns {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-template-rows: auto;
  grid-gap: 15px;
  background-image: url('./img/bg-btns.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 150%;
}
.payment-methodes .btns > * {
  height: fit-content;
  border: none;
  padding: 7px;
  cursor: pointer;
  font-size: 10px;
  text-decoration: none;
  text-align: center;
}
.payment-methodes .btns .btn-yellow {
  background-color: #ecec26;
  color: #333;
}
.payment-methodes .btns .btn-black {
  background-color: #ddd;
  margin-top: auto;
  color: #0000ff;
  border: 1px dashed #ecec26;
  border-image: url("./img/border.svg") 1;
  filter: invert(1);
}
@media screen and (max-width: 1360px) {
  .payment-methodes {
      grid-template-columns: 12fr;
      grid-template-rows: auto auto;
  }
  .payment-methodes>div:nth-child(1) {
      grid-template-columns: 6fr 6fr;
      grid-template-rows: auto;
  }
  .payment-methodes>div:nth-child(2) {
      grid-template-columns: 12fr;
      grid-template-rows: auto auto;
  }
  .payment-methodes .address {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;
  }
  .payment-methodes .address p {
      margin: 0;
      border-radius: 8px;
      word-break: break-all;
      max-width: none;
  }
  .payment-methodes .address button:nth-child(1) {
      width: 100%;
  }
  .payment-methodes .box-items {
      width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .payment-methodes .box-items {
      grid-template-columns: 12fr;
      grid-template-rows: repeat(4, auto);
  }
}

.methods-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.transaction-overview {
  background-color: rgba(194, 200, 232, 0.6);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 15px;
  width: 100%;
  padding: 15px;
  display: grid;
  grid-template-columns: auto 200px;
  grid-template-rows: repeat(4, auto);
  grid-gap: 15px;
}

.transaction-overview h3 {
  font-size: 14px;
  word-break: break-all;
  padding: 10px 17px;
  margin: 0;
  border-radius: 15px;
  box-shadow: 0 5px 8px #3334,
              0 0 8px #3332;
  background-color: #4b5aa7;
  color: #9fa9d9;
}
.transaction-overview h3:nth-child(odd) {
  background-color: #d0d6ee;
  color: #4b5aa7;
}
.transaction-overview h3 span {
  color: inherit !important;
}

.date {
  grid-column: 2/-1;
  grid-row: 1/-1;
  background-color: #4b5aa7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 30px 15px;
  border-radius: 50px;
}
.date .title-date {
  color: #ecec26;
  font-weight: bolder;
}

.date span:not(.title-date) {
  background-color: #d0d6ee;
  display: inline-block;
  padding: 7px;
  width: 100%;
  text-align: center;
  color: #4b5aa7;
  border-radius: 8px;
  box-shadow: 0 5px 5px #3333;
}

.transaction-overview h3 span {
  color: #4b5aa7;
  font-size: 13px;
  margin-left: 5px;
}

@media screen and (max-width: 1300px) {
  .transaction-overview {
    grid-template-columns: 12fr;
    grid-template-rows: repeat(5, auto);
  }
  .date {
    grid-column: 1/-1;
    grid-row: 5/-1;
  }
}


.income-overview {
  width: 100%;
  height: 347px;
  background-color: rgba(194, 200, 232, 0.6);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 15px;
}
.yourLink {
  width: 100%;
}

.yourLink .textContainer {
  margin-top: 0;
  width: unset;
  margin-bottom: 0;
  margin-left: 0;
}

.yourLink .textContainer .circleTitle {
  width: 130px;
  height: 70%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  background-color: #9fa9d9;
}

.yourLink .textContainer .circleTitle p:first-child {
  margin-top: 11px;
  opacity: 0.6;
}

.yourLink .textContainer .circleTitle p:last-child {
  color: #d5d5d5;
  opacity: 0.8;
  text-transform: normal !important;
  font-size: 13px;
}

.yourLink .textContainer .caption p:first-child,
.yourLink .textContainer .caption span:first-child {
  margin-bottom: 15px;
}

.yourLink .textContainer .caption {
  background-color: rgba(194, 200, 232, 0.6);
}

.yourLink .textContainer .caption p,
.yourLink .textContainer .caption span {
  font-size: 13px;
  font-weight: 900;
  line-height: 15px;
  margin: 0;
}

.yourLink .textContainer .caption {
  padding: 12px 135px 12px 20px;
  box-shadow: none !important;
}

.yourLink .textContainer .caption p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.yourLink .textContainer .caption span {
  font-size: 10px;
}

.home .right-container {
  margin-left: 10px;
  width: 35%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.home .right-container .profile-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: rgba(194, 200, 232, 0.6);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: relative;
}

.profile-container {
  padding: 20px;
  box-sizing: border-box;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.profile-container .top-area {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  transition: all 0.4s ease;
  padding: 10px;
}

.profile-container .top-area .image-container {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: rgb(253, 252, 252);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  position: relative;
  z-index: 2;
}

.profile-container .top-area .image-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 95%;
  width: 95%;
  background-color: rgb(7, 7, 7);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
}

.profile-container .top-area .image-container img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.profile-container .top-area > p {
  font-size: 20px;
  font-weight: 900;
  color: #3c4572;
  letter-spacing: 1.2px;
  margin: 15px;
}

.profile-container .icon-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 5px;
}

.profile-container .icon-bar .item {
  width: 40px;
  height: 40px;
  margin-right: 3px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #b3bdef;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.top-icon {
  position: absolute;
  top: 30px;
  left: 20px;
  margin: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.closebtn svg {
  fill: white !important;
}

.closebtnShowchat {
  position: absolute;
  top: 50px;
  right: 30px;
  background-color: #3c4572;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white !important;
  height: 24px;
  border-radius: 8px 0 0 8px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -ms-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
}

.closebtnShowchat::after {
  content: "";
  position: absolute;
  width: 19px;
  height: 20px;
  right: -8px;
  top: 2.2px;
  background-color: #3c4572;
  border-radius: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.closebtn {
  position: absolute;
  top: 50px;
  right: 30px;
  background-color: #3c4572;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white !important;
  height: 24px;
  border-radius: 8px 0 0 8px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -ms-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
}
.closebtn::after {
  content: "";
  position: absolute;
  width: 19px;
  height: 20px;
  right: -8px;
  top: 2.2px;
  background-color: #3c4572;
  border-radius: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.icon-item {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #b3bdef;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.profile-container .icon-bar .item svg,
.icon-item svg {
  width: 60%;
  cursor: pointer;
}

.switch-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
  background-color: #5f699a18;
  margin-bottom: 15px;
  width: 100%;
}

.switch-bar .item {
  width: 50%;
  box-sizing: border-box;
  padding: 8px 15px;
  border-radius: 9px;
  color: #3c4572;
  font-weight: 700;
  font-size: 16px;
  margin: 10px 15px;
  text-align: center;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
}

.switch-bar .active {
  background-color: #b3bdef;
  cursor: pointer;
}

.switch-bar .disabeld {
  opacity: 25%;
  position: relative;
  background-color: rgba(203, 205, 232, 0.137);
  cursor: not-allowed;
}

.switch-bar .disabeld .lock {
  color: #101425;
  opacity: 1 !important;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
}

.profile-container .buttom-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-profile,
.account-setting,
.security {
  height: 0;
  width: 100%;
  /* margin: 20px 0; */
}

.edit-profile .item.action,
.security .item.action,
.account-setting .item.action {
  height: 30px;
  position: relative;
  margin-top: 60px;
}

.edit-profile input[type="text"],
.edit-profile label {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
}

.edit-profile input[type="text"]::placeholder {
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
}

.edit-profile .item.action .button-container,
.security .item.action .button-container,
.account-setting .item.action .button-container {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 80%;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
}

.button-container button {
  color: black;
  margin: 20px;
  width: 80px;
  font-weight: 600;
  margin-top: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 3px 7px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.button-container .reset {
  background-color: #d23e44;
  padding: 7px;
}

.button-container .save {
  background-color: #69cc44;
  padding: 7px;
}

.profile-container .buttom-area .item {
  border-radius: 9px;
  background-color: #b3bdef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  margin-bottom: 7px;
  width: 100%;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  cursor: pointer;
  position: relative;
}

.profile-container .buttom-area .item p {
  margin: 0;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.profile-container .buttom-area .item span {
  margin: 0;
  color: #4b5aa7 !important;
  font-weight: 600;
  font-size: 15px;
}

.profile-container .buttom-area .item span svg {
  fill: #4b5aa7 !important;
}

.account-setting .item .input {
  width: 40%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4b5aa7f5;
  border-radius: 7px;
  height: 110%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.account-setting .item #email {
  width: 65%;
}

.account-setting .item .input input {
  margin-right: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  font-size: 12px;
  text-align: center;
  padding: 0 7px 0 7px;
  color: #ffffff78;
}

.home .right-container .options-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home .right-container .options-container .option {
  width: 100%;
}

.other-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.other-info-container .info-item {
  position: relative;
  width: 100%;
  margin-bottom: 33px;
}

.other-info-container .info-item:first-child {
  margin-top: 20px;
}

.Authentication-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.qr-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qr-code p {
  max-width: 200px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.9;
}

.Authentication-description {
  padding: 20px;
  border-radius: 5px;
  background-color: #b3bdef;
  color: #4b5aa7;
  width: 99%;
}

.Authentication-description p {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.8;
}

.Authentication-description h3 {
  font-size: 25px;
  width: 260px;
  font-weight: 600;
}

.Authentication-description h3 span {
  font-size: 14px;
  word-wrap: break-word;
}
.other-info-container .info-item p {
  padding: 25px 30px 25px 100px;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  border-radius: 20px;
  background-color: rgba(194, 200, 232, 0.6);
  color: #4b5aa7;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.other-info-container .info-item > h5 {
  position: absolute;
  left: 15px;
  top: 50%;
  width: 78px;
  text-align: center;
  margin: 0;
  font-size: large;
  font-weight: 900;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  word-break: break-all;
}

.other-info-container .circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  height: 88px;
  width: 88px;
  background-color: #9ba0e1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.other-info-container .circle2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  height: 70px;
  width: 70px;
  background-color: #9ba0e1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  box-shadow: 5px 6px 6px 0 rgba(0, 0, 0, 0.16);
}

.other-info-container .circle2 h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0 0 0 / 6%);
  margin: 0;
  font-size: 53px;
}

@media screen and (max-width: 1350px) {
  .account-setting .item .input {
    width: 35%;
  }

  .account-setting .item #email {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .mainContainer.home {
    flex-direction: column-reverse;
  }

  .home .right-container,
  .home .center-container {
    width: 100%;
  }

  .home .right-container {
    margin-left: 0px;
  }

  .home .center-container {
    margin-right: 0px;
  }

  .switch-bar .item {
    padding: 5px 10px;

    margin: 10px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainer.home {
    margin-top: 65px;
    margin-left: 0px;
    margin-right: 0px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }

  .minContainer {
    display: unset !important;
  }
}

@media screen and (max-width: 475px) {
  .methods-container {
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 450px) {
  .account-setting .item #email {
    width: 60%;
  }

  .profile-container .buttom-area .item p,
  .edit-profile input[type="text"],
  .edit-profile input[type="text"]::placeholder,
  .edit-profile label {
    font-size: 13px;
  }

  .other-info-container .info-item p {
    font-size: 13px;
  }

  .other-info-container .info-item > h5 {
    font-size: 14px;
  }
}

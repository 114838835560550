.center-container {
    width: 100%;
    margin-right: 30px;
}
.documents {
    background-color: #d1d6ef;
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
}
@media screen and (max-width: 770px) {
    .documents-res {
        margin-left: 20px;
        width: calc(100% - 10px);
    }
}
.document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    color: #4b5aa7;
}
.document img {
    width: 200px;
    height: 200px;
    border-radius: 15px;
    object-fit: cover;
    box-shadow: 0 5px 15px #8e93aa;
}
.sidebar {
  background-color     : rgba(194, 200, 232, 0.72);
  width                : 100%;
  border-radius        : 20px;
  padding              : 30px 0;
  box-sizing           : border-box;
  /*height             : calc(100vh - 100px);
  */
  display              : flex;
  min-height           : 540px;
  height               : fit-content;
  margin               : 20px;
  justify-content      : center;
  transition           : all 0.3s ease;
  flex-direction       : column;
  -webkit-border-radius: 20px;
  -moz-border-radius   : 20px;
  -ms-border-radius    : 20px;
  -o-border-radius     : 20px;
  -webkit-transition   : all 0.3s ease;
  -moz-transition      : all 0.3s ease;
  -ms-transition       : all 0.3s ease;
  -o-transition        : all 0.3s ease;
}

.sidebar .navbar {
  height     : 90%;
  width      : 100%;
  display    : flex;
  align-items: center;
}

.sidebar .navbar ul {
  list-style     : none;
  display        : flex;
  justify-content: center;
  flex-direction : column;
  height         : 100%;
  overflow       : hidden;
  padding        : 0 !important;
  width          : 100%;
}

.sidebar .navbar ul li {
  font-size   : 17px;
  padding-left: 20px;
  font-weight : 600;
  margin      : 10px 0;
}

.sidebar .navbar ul li a {
  text-decoration          : none;
  line-height              : 30px;
  color                    : #b2b7d2;
  display                  : inline-block;
  width                    : 100%;
  padding                  : 6px 10px 6px 10px;
  border-top-left-radius   : 15px;
  border-bottom-left-radius: 15px;
  display                  : flex;
  align-items              : center;
}

.sidebar .navbar ul li a span svg {
  width : 100%;
  height: 100%;
}

.sidebar .navbar ul li a span {
  margin-right   : 10px;
  display        : flex;
  align-content  : center;
  justify-content: center;
  width          : 27px;

}

.sidebar .navbar ul li a.activelink {
  color           : #3c4572;
  background-color: #e9ecfb;
}

.sidebar .navbar ul li a.activelink span svg path {
  fill: #3c4572 !important;
}

.sidebar .navbar ul li a img {
  width       : 25px;
  height      : 30px;
  position    : relative;
  top         : 5px;
  margin-right: 5px;
}

.mobile-menu svg {
  position: relative;
  top     : 10px;
  left    : 10px;
  width   : 25px;
  height  : 25px;
  z-index : 3;
}

@media (min-width: 768px) {
  .sidebar {
    width       : 25%;
    margin-right: 20px;
    max-width   : 300px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 80%;
    align-self: center;
    margin : 0;
  
    padding: 28px 0 30px 0px;
  }
}
.copyright-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.copyright-container p {
  text-align: center;
  color: #ebd778;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .copyright-container {
    display: none !important;
  }
}

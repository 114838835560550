.transfer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  padding: 20px 0;
  width: 100%;
  height: 100%;
}

.transfer .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--primery-color);
  border-radius: 24px;
  width: 80% !important;
  height: 70%;
  padding: 20px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .transfer .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--primery-color);
    border-radius: 24px;
    width: 100% !important;
    height: 70%;
    padding: 20px;
    margin-top: 30px;
  }
}

.transfer .box .btn-group {
  margin: 80px 0 0 0;
  display: flex;
}

.transfer .box .btn-group button {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--button-color-3);
  width: 175px;
  font-weight: bold;
  border: none;
  height: 54px;
  border-radius: 12px;
  font-size: 16px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
}

.transfer .box .input-group {
  margin: 0 0 32px 0;
  display: flex;
  flex-direction: column;
}

.transfer .box .input-group:last-child {
  margin-bottom: 0;
}

.transfer .box .input-group label {
  display: block;
  padding: 0 0 8px 8px;
  font-size: 14px;
  font-weight: bold;
  color: var(--box-color);
}

.transfer .box .input-group .content-box {
  position: relative;
}

.transfer .box .input-group .input-box {
  background-color: var(--back-color);
  border-radius: 10px;
  height: 80px;
  width: 400px;
  max-width: 100%;
  position: relative;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
  padding: 0 12px;
  z-index: 9;
}

.transfer .box .input-group .input-box input {
  height: 100%;
  max-height: 100%;
  font-size: 18px;
  font-weight: bold;
  width: calc(100% - 100px);
  border: none;
  background-color: transparent;
  color: #d1d6ef;
}

.transfer .box .input-group .input-box .select {
  display: flex;
  align-items: center;
  border: none;
}

.transfer .box .input-group .input-box .select svg {
  height: 36px;
  width: 36px;
}

.transfer .box .input-group .input-box .select span {
  font-size: 16px;
  font-weight: 900;
  margin: 0 0 0 4px;
  color: var(--button-color);
}

.transfer .box .input-group .content-box .tooltip {
  position: absolute;
  left: 8px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
  top: calc(100% - 6px);
  background-color: #ffdc4a;
  border-radius: 10px;
  padding: 8px;
  z-index: 1;
}

.transfer .box .input-group .content-box .tooltip span {
  color: var(--primery-color);
  font-size: 14px;
  font-weight: bold;
}

.transfer .box .input-group .input-box .select {
  position: absolute;
  border-radius: 10px;
  background-color: var(--secondry-color);
  width: 100px;
  height: 48px;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.transfer .box .confirm-step {
  width: 60%;
  max-width: 100%;
}

.transfer .box .confirm-step .desc {
  width: 80%;
  margin: 0 auto 50px auto;
}

.transfer .box .confirm-step .desc p {
  font-size: 16px;
  font-weight: 900;
  color: var(--button-color-2);
  line-height: 2;
}

.token {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.token .logo,
.token .copy {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.token .logo svg {
  height: 54px;
  width: 54px;
}

.token .token-box {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;
  height: auto;
  background-color: var(--back-color);
  border-radius: 10px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  overflow-x: visible;
}

.token .token-box span {
  font-size: 16px;
  font-weight: 900;
  color: var(--button-color-3);
  word-break: break-all;
}

.token .copy button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: #fff;
  background-color: var(--button-color-3);
  border-radius: 14px;
  height: 54px;
  width: 64px;
}

.token .copy span,
.token .logo span {
  display: block;
  font-size: 16px;
  font-weight: 900;
  margin: 12px 0 0 0;
  color: var(--button-color);
  text-align: center;
}

.coin-select {
  background-color: #5162bb;
  padding: 0 5px;
  height: 33px;
  cursor: pointer;
  color: #ebecf1;
  border: none;
  width: 100%;
}

.coin-select:focus {
  outline: none !important;
}

.spinner-border {
  display: inline-block;
  padding: 10px;
  border: 4px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  color: #f8f9fa;
}
@keyframes spinner-border{
  to{transform:rotate(360deg)}
}
.history-title {
  position: relative;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 17px 20px 14px 0 rgba(81, 98, 187, 0.16);
  background-color: #5162bb;
  color: #f5f5f5;
  margin: 20px 20px 20px 0;
  margin-left: 45px;
  align-self: flex-start;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.history-title::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  bottom: -10px;
  left: 100px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  background-color: #5162bb;
}

.history-title p {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 2px;
}

.history-title p span {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.history-title p span svg {
  width: 100%;
  height: 100%;
}

.transaction-container {
  height: 320px;
  overflow-y: scroll;
}
.icon {
  height: 70%;
  width: 50px;
  margin-right: 10px;
  border-radius: 12px;
  box-shadow: 9px 6px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
}
.hash {
  word-break: break-word !important;
}

@media screen and (max-width: 560px) {
   .transaction-container {
    height: 320px;
    overflow-y: scroll;
    /*max-width: 350px;*/
  }
  .trnasaction-main {
    /*margin-left: -35px;*/
    width: 100% !important;
  }
  .history-title {
    margin-left: 15px;
  }
  .trxrow {
    max-width: 300px;
    /*margin-left: -20px;*/
  }
  .trxrow.transction {
    max-width: 300px;
    /*overflow: hidden;*/
  }

  .icon {
    /*height: 30%;*/
    border-radius: 6px;
  }
  .amount {
    margin-left: -20px;
    position: absolute;
    left: 200px;
    top: -10px;
  }
  .sendrec {
    font-size: 10px;
  }
  .hash {
    font-size: 5px;
    word-break: break-word !important;
  }
}

@media screen and (max-width: 428px) {
  .wallet-manage .actions {
    flex: 3;
  }

  .wallet-manage .content {
    flex: 2;
  }

  .wallet-manage .actions .resive,
  .wallet-manage .actions .send {
    width: 100%;
    padding: 10px 0;
  }

  /* .icon {
    height: 50%;
    width: 30px;
    font-size: 30px;
    line-height: 30px;
    border-radius: 6px;
  } */
}

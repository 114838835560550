.credit-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 4px 32px 12px;
  background-color: #d1d6ef;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 20px !important;
}

.credit-cards .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.credit-cards .title h4 {
  flex-grow: 0;
  color: #9a9a9a;
  font-size: 16px;
  font-weight: 900;
}

.credit-cards .title .arrows {
  display: flex;
  flex-grow: 0;
}

.credit-cards .title .arrows button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  border-radius: 50%;
  background-color: transparent;
  color: var(--primery-color);
  border: 1px solid var(--primery-color);
}

.credit-cards .title .arrows button:first-child {
  margin-left: 0;
}

.credit-cards .title .arrows button:last-child {
  margin-right: 0;
}

.credit-cards .images {
  text-align: center;
  margin: 0 0 16px 0;
}

.credit-cards .images img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

.credit-cards .images img.d-none {
  display: none;
}

.credit-cards .dots ul {
  margin: 0;
  padding: 0;
}

.credit-cards .dots ul > li {
  position: relative;
  display: inline-block;
  margin: 0 7px;
}
.credit-cards .dots ul > li:first-child {
  margin-left: 0;
}
.credit-cards .dots ul > li:last-child {
  margin-right: 0;
}
.credit-cards .dots ul > li button {
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #9a9a9a;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.notifies {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow-y: scroll;
}
.notifies .notify-item {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: fit-content;
  box-shadow: inset 1px 2px 19px 0px #00000052;
  border-radius: 17px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 19px;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 13px;
  position: relative;
}
.notifies .notify-item p {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-item {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 300px !important;
  box-shadow: inset 1px 2px 19px 0px #00000052;
  border-radius: 17px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 25px;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 14px;
  overflow-y: scroll;
  text-align: justify;
}

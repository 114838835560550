@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@font-face {
  font-family: "Microsoft YaHei";
  src: url("/static/media/Microsoft YaHei.472f3416.eot");
  src: url("/static/media/Microsoft YaHei.472f3416.eot") format("embedded-opentype"),
    url("/static/media/Microsoft YaHei.f3efdd29.woff2") format("woff2"),
    url("/static/media/Microsoft YaHei.0a5ab34e.woff") format("woff"),
    url("/static/media/Microsoft YaHei.bc291809.ttf") format("truetype");
}

body {
  margin: 0 !important;
  font-family: "Microsoft YaHei" !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
:root {
  --button-color: #e9e9e9;
  --button-color-2: #cecece;
  --button-color-3: #5162bb;
  --primery-color: #3c4572;
  --secondry-color: #5563a7;
  --box-color: rgba(194, 200, 232, 0.6);
  --back-color: #e9ecfb;
}

.verify-mobile {
  transition: all 1s ease-in;
  width: 90%;
  padding: 15px;
  height: 10px;
  border-radius: 20px;
  border: none;
  background: #f1f1f1;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.verify-btn {
  width: 95% !important;
  padding: 15px;
  border-radius: 20px;
  border: none;
  background: #ebd788;
  margin-bottom: 20px;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5162bb;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5162bb;
  border-radius: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MuiInputBase-root:before {
  border-bottom: none !important;
  border: none !important;
}
.MuiInputBase-root:active {
  border-bottom: none !important;
  outline: none !important;
}

.forget-pass-field {
  background-color: khaki !important;
  width: 95%;
  height: 45px;
  border-radius: 45px;
  color: #282c34;
  font-size: 18px;
  padding-left: 20px;
  border: none !important;
}

.confirmBtn {
  margin-top: 20px;
  height: 40px;
  width: 70%;
  background-color: #5162bb;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.mainContainer.home * {
  box-sizing: border-box;
}

.mainContainer.home {
  display: flex;
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home .center-container {
  margin-right: 10px;
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home .center-container .search-container {
  border-radius: 30px;
  height: 50px;
  width: 100%;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.6);
  -o-border-radius: 30px;
  display: flex;
}

.home .center-container .search-container input {
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  background-color: transparent;
  border: none;
  color: #3c4572;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
}

.home .center-container .search-container input::-webkit-input-placeholder {
  color: #b2b7d2;
  font-size: 15px;
  font-weight: 600;
}

.home .center-container .search-container input:-ms-input-placeholder {
  color: #b2b7d2;
  font-size: 15px;
  font-weight: 600;
}

.home .center-container .search-container input::placeholder {
  color: #b2b7d2;
  font-size: 15px;
  font-weight: 600;
}

.home .center-container .search-container span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
}

.home .center-container .search-container span path {
  fill: #b2b7d2;
}

.home .center-container .search-container input:focus ~ span path {
  color: #3c4572;
}

input:focus {
  outline: none;
}


.payment-methodes {
  border-radius: 30px;
  background-color: rgba(194, 200, 232);
  background-repeat: round;
  background-size: cover;
  width: 100%;
  max-width: 858px;
  margin-top: 15px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 20px;
  display: grid;
  grid-template-columns: 2.5fr 9.5fr;
  grid-template-rows: auto;
  grid-gap: 15px;
  min-height: 200px;
  height: auto;
  box-sizing: border-box;
}
.payment-methodes * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Microsoft Tai Le";
  font-weight: bold;
}

.payment-methodes>div {
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: 40px auto;
  grid-gap: 15px;
}

.payment-methodes h2 {
  width: 100%;
  text-align: left;
  font-size: 24px;
  color: #3c4572;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 800;
  text-align: center;
  margin: auto;
}

.payment-methodes img {
  width: 170px;
  margin: auto;
}
.payment-methodes .address {
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-methodes .address button {
  padding: 6px 15px;
  background-color: #4b5aa7;
  border: none;
  height: 100%;
  font-size: 12px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #4b5aa7;
}
.payment-methodes .icon-copy {
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.payment-methodes .icon-copy img {
  width: 18px;
}
.payment-methodes .address span {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.payment-methodes .address p {
  width: 100%;
  color: #4b5aa7;
  padding: 6px 15px 7px 25px;
  overflow-x: hidden;
  font-size: 11px;
  border: 1px solid #4b5aa7;
  margin-left: -10px;
  text-align: center;
  border-radius: 0 8px 8px 0;
  max-width: 473px;
  text-overflow: ellipsis;
}
.payment-methodes .box-items {
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-template-rows: -webkit-min-content -webkit-min-content;
  grid-template-rows: min-content min-content;
  grid-gap: 15px;
  margin: auto 0;
}
.payment-methodes .item {
  padding: 10px;
  background-color: #b3bdef99;
  box-shadow: 5px 5px 5px rgba(172, 172, 172, 0.4);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  color: #3c4572;
  font-size: 11px;
}
.payment-methodes .item span {
  border: 1px dashed #3c4572;
  text-align: center;
  padding: 5px;
  border-image: url(/static/media/border.591a855a.svg) 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 10px;
  word-break: break-all;
}
.payment-methodes .btns {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-template-rows: auto;
  grid-gap: 15px;
  background-image: url(/static/media/bg-btns.0e467976.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 150%;
}
.payment-methodes .btns > * {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  padding: 7px;
  cursor: pointer;
  font-size: 10px;
  text-decoration: none;
  text-align: center;
}
.payment-methodes .btns .btn-yellow {
  background-color: #ecec26;
  color: #333;
}
.payment-methodes .btns .btn-black {
  background-color: #ddd;
  margin-top: auto;
  color: #0000ff;
  border: 1px dashed #ecec26;
  border-image: url(/static/media/border.591a855a.svg) 1;
  -webkit-filter: invert(1);
          filter: invert(1);
}
@media screen and (max-width: 1360px) {
  .payment-methodes {
      grid-template-columns: 12fr;
      grid-template-rows: auto auto;
  }
  .payment-methodes>div:nth-child(1) {
      grid-template-columns: 6fr 6fr;
      grid-template-rows: auto;
  }
  .payment-methodes>div:nth-child(2) {
      grid-template-columns: 12fr;
      grid-template-rows: auto auto;
  }
  .payment-methodes .address {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      grid-gap: 15px;
      gap: 15px;
  }
  .payment-methodes .address p {
      margin: 0;
      border-radius: 8px;
      word-break: break-all;
      max-width: none;
  }
  .payment-methodes .address button:nth-child(1) {
      width: 100%;
  }
  .payment-methodes .box-items {
      width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .payment-methodes .box-items {
      grid-template-columns: 12fr;
      grid-template-rows: repeat(4, auto);
  }
}

.methods-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.transaction-overview {
  background-color: rgba(194, 200, 232, 0.6);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 15px;
  width: 100%;
  padding: 15px;
  display: grid;
  grid-template-columns: auto 200px;
  grid-template-rows: repeat(4, auto);
  grid-gap: 15px;
}

.transaction-overview h3 {
  font-size: 14px;
  word-break: break-all;
  padding: 10px 17px;
  margin: 0;
  border-radius: 15px;
  box-shadow: 0 5px 8px #3334,
              0 0 8px #3332;
  background-color: #4b5aa7;
  color: #9fa9d9;
}
.transaction-overview h3:nth-child(odd) {
  background-color: #d0d6ee;
  color: #4b5aa7;
}
.transaction-overview h3 span {
  color: inherit !important;
}

.date {
  grid-column: 2/-1;
  grid-row: 1/-1;
  background-color: #4b5aa7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 30px 15px;
  border-radius: 50px;
}
.date .title-date {
  color: #ecec26;
  font-weight: bolder;
}

.date span:not(.title-date) {
  background-color: #d0d6ee;
  display: inline-block;
  padding: 7px;
  width: 100%;
  text-align: center;
  color: #4b5aa7;
  border-radius: 8px;
  box-shadow: 0 5px 5px #3333;
}

.transaction-overview h3 span {
  color: #4b5aa7;
  font-size: 13px;
  margin-left: 5px;
}

@media screen and (max-width: 1300px) {
  .transaction-overview {
    grid-template-columns: 12fr;
    grid-template-rows: repeat(5, auto);
  }
  .date {
    grid-column: 1/-1;
    grid-row: 5/-1;
  }
}


.income-overview {
  width: 100%;
  height: 347px;
  background-color: rgba(194, 200, 232, 0.6);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 15px;
}
.yourLink {
  width: 100%;
}

.yourLink .textContainer {
  margin-top: 0;
  width: unset;
  margin-bottom: 0;
  margin-left: 0;
}

.yourLink .textContainer .circleTitle {
  width: 130px;
  height: 70%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  background-color: #9fa9d9;
}

.yourLink .textContainer .circleTitle p:first-child {
  margin-top: 11px;
  opacity: 0.6;
}

.yourLink .textContainer .circleTitle p:last-child {
  color: #d5d5d5;
  opacity: 0.8;
  text-transform: normal !important;
  font-size: 13px;
}

.yourLink .textContainer .caption p:first-child,
.yourLink .textContainer .caption span:first-child {
  margin-bottom: 15px;
}

.yourLink .textContainer .caption {
  background-color: rgba(194, 200, 232, 0.6);
}

.yourLink .textContainer .caption p,
.yourLink .textContainer .caption span {
  font-size: 13px;
  font-weight: 900;
  line-height: 15px;
  margin: 0;
}

.yourLink .textContainer .caption {
  padding: 12px 135px 12px 20px;
  box-shadow: none !important;
}

.yourLink .textContainer .caption p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.yourLink .textContainer .caption span {
  font-size: 10px;
}

.home .right-container {
  margin-left: 10px;
  width: 35%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.home .right-container .profile-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: rgba(194, 200, 232, 0.6);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: relative;
}

.profile-container {
  padding: 20px;
  box-sizing: border-box;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.profile-container .top-area {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  transition: all 0.4s ease;
  padding: 10px;
}

.profile-container .top-area .image-container {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: rgb(253, 252, 252);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  position: relative;
  z-index: 2;
}

.profile-container .top-area .image-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 95%;
  width: 95%;
  background-color: rgb(7, 7, 7);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
}

.profile-container .top-area .image-container img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.profile-container .top-area > p {
  font-size: 20px;
  font-weight: 900;
  color: #3c4572;
  letter-spacing: 1.2px;
  margin: 15px;
}

.profile-container .icon-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 5px;
}

.profile-container .icon-bar .item {
  width: 40px;
  height: 40px;
  margin-right: 3px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #b3bdef;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.top-icon {
  position: absolute;
  top: 30px;
  left: 20px;
  margin: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.closebtn svg {
  fill: white !important;
}

.closebtnShowchat {
  position: absolute;
  top: 50px;
  right: 30px;
  background-color: #3c4572;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white !important;
  height: 24px;
  border-radius: 8px 0 0 8px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -ms-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
}

.closebtnShowchat::after {
  content: "";
  position: absolute;
  width: 19px;
  height: 20px;
  right: -8px;
  top: 2.2px;
  background-color: #3c4572;
  border-radius: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.closebtn {
  position: absolute;
  top: 50px;
  right: 30px;
  background-color: #3c4572;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white !important;
  height: 24px;
  border-radius: 8px 0 0 8px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -ms-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
}
.closebtn::after {
  content: "";
  position: absolute;
  width: 19px;
  height: 20px;
  right: -8px;
  top: 2.2px;
  background-color: #3c4572;
  border-radius: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.icon-item {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #b3bdef;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.profile-container .icon-bar .item svg,
.icon-item svg {
  width: 60%;
  cursor: pointer;
}

.switch-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
  background-color: #5f699a18;
  margin-bottom: 15px;
  width: 100%;
}

.switch-bar .item {
  width: 50%;
  box-sizing: border-box;
  padding: 8px 15px;
  border-radius: 9px;
  color: #3c4572;
  font-weight: 700;
  font-size: 16px;
  margin: 10px 15px;
  text-align: center;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
}

.switch-bar .active {
  background-color: #b3bdef;
  cursor: pointer;
}

.switch-bar .disabeld {
  opacity: 25%;
  position: relative;
  background-color: rgba(203, 205, 232, 0.137);
  cursor: not-allowed;
}

.switch-bar .disabeld .lock {
  color: #101425;
  opacity: 1 !important;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
}

.profile-container .buttom-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-profile,
.account-setting,
.security {
  height: 0;
  width: 100%;
  /* margin: 20px 0; */
}

.edit-profile .item.action,
.security .item.action,
.account-setting .item.action {
  height: 30px;
  position: relative;
  margin-top: 60px;
}

.edit-profile input[type="text"],
.edit-profile label {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
}

.edit-profile input[type="text"]::-webkit-input-placeholder {
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
}

.edit-profile input[type="text"]:-ms-input-placeholder {
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
}

.edit-profile input[type="text"]::placeholder {
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
}

.edit-profile .item.action .button-container,
.security .item.action .button-container,
.account-setting .item.action .button-container {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 80%;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
}

.button-container button {
  color: black;
  margin: 20px;
  width: 80px;
  font-weight: 600;
  margin-top: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 3px 7px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.button-container .reset {
  background-color: #d23e44;
  padding: 7px;
}

.button-container .save {
  background-color: #69cc44;
  padding: 7px;
}

.profile-container .buttom-area .item {
  border-radius: 9px;
  background-color: #b3bdef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  margin-bottom: 7px;
  width: 100%;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  cursor: pointer;
  position: relative;
}

.profile-container .buttom-area .item p {
  margin: 0;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.profile-container .buttom-area .item span {
  margin: 0;
  color: #4b5aa7 !important;
  font-weight: 600;
  font-size: 15px;
}

.profile-container .buttom-area .item span svg {
  fill: #4b5aa7 !important;
}

.account-setting .item .input {
  width: 40%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4b5aa7f5;
  border-radius: 7px;
  height: 110%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.account-setting .item #email {
  width: 65%;
}

.account-setting .item .input input {
  margin-right: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  font-size: 12px;
  text-align: center;
  padding: 0 7px 0 7px;
  color: #ffffff78;
}

.home .right-container .options-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home .right-container .options-container .option {
  width: 100%;
}

.other-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.other-info-container .info-item {
  position: relative;
  width: 100%;
  margin-bottom: 33px;
}

.other-info-container .info-item:first-child {
  margin-top: 20px;
}

.Authentication-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.qr-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qr-code p {
  max-width: 200px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.9;
}

.Authentication-description {
  padding: 20px;
  border-radius: 5px;
  background-color: #b3bdef;
  color: #4b5aa7;
  width: 99%;
}

.Authentication-description p {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.8;
}

.Authentication-description h3 {
  font-size: 25px;
  width: 260px;
  font-weight: 600;
}

.Authentication-description h3 span {
  font-size: 14px;
  word-wrap: break-word;
}
.other-info-container .info-item p {
  padding: 25px 30px 25px 100px;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  border-radius: 20px;
  background-color: rgba(194, 200, 232, 0.6);
  color: #4b5aa7;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.other-info-container .info-item > h5 {
  position: absolute;
  left: 15px;
  top: 50%;
  width: 78px;
  text-align: center;
  margin: 0;
  font-size: large;
  font-weight: 900;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  word-break: break-all;
}

.other-info-container .circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  height: 88px;
  width: 88px;
  background-color: #9ba0e1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.other-info-container .circle2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  height: 70px;
  width: 70px;
  background-color: #9ba0e1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  box-shadow: 5px 6px 6px 0 rgba(0, 0, 0, 0.16);
}

.other-info-container .circle2 h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0 0 0 / 6%);
  margin: 0;
  font-size: 53px;
}

@media screen and (max-width: 1350px) {
  .account-setting .item .input {
    width: 35%;
  }

  .account-setting .item #email {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .mainContainer.home {
    flex-direction: column-reverse;
  }

  .home .right-container,
  .home .center-container {
    width: 100%;
  }

  .home .right-container {
    margin-left: 0px;
  }

  .home .center-container {
    margin-right: 0px;
  }

  .switch-bar .item {
    padding: 5px 10px;

    margin: 10px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainer.home {
    margin-top: 65px;
    margin-left: 0px;
    margin-right: 0px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }

  .minContainer {
    display: unset !important;
  }
}

@media screen and (max-width: 475px) {
  .methods-container {
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 450px) {
  .account-setting .item #email {
    width: 60%;
  }

  .edit-profile input[type="text"]::-webkit-input-placeholder {
    font-size: 13px;
  }

  .edit-profile input[type="text"]:-ms-input-placeholder {
    font-size: 13px;
  }

  .profile-container .buttom-area .item p,
  .edit-profile input[type="text"],
  .edit-profile input[type="text"]::placeholder,
  .edit-profile label {
    font-size: 13px;
  }

  .other-info-container .info-item p {
    font-size: 13px;
  }

  .other-info-container .info-item > h5 {
    font-size: 14px;
  }
}


.minContainer{
  margin: 0 auto;
  width: 100vw;
  max-width: 1700px;
  display: flex;
}
.layoutContainer {
  background-image: url(/static/media/back.85da5ce5.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
background-attachment: fixed;
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  /* overflow: hidden; */
}
.layoutContainer .sidebar {
  position: sticky;
  top: 20px;
  min-height: calc(100vh - 40px) !important;
}
@media screen and (max-width: 786px) {
  .container {
    width: 100%;
  }
  .layoutContainer .sidebar {
    position: sticky;
    top: 20px;
    min-height: -webkit-fit-content !important;
    min-height: -moz-fit-content !important;
    min-height: fit-content !important;
  }
  .layoutContainer {
    overflow: hidden;
  }
}
.contents {
  margin-left: 20px;
  width: 100%;
}
#back-to-top-anchor{
  width: 0;
  height: 0;
}



.sidebar {
  background-color     : rgba(194, 200, 232, 0.72);
  width                : 100%;
  border-radius        : 20px;
  padding              : 30px 0;
  box-sizing           : border-box;
  /*height             : calc(100vh - 100px);
  */
  display              : flex;
  min-height           : 540px;
  height               : -webkit-fit-content;
  height               : -moz-fit-content;
  height               : fit-content;
  margin               : 20px;
  justify-content      : center;
  transition           : all 0.3s ease;
  flex-direction       : column;
  -webkit-border-radius: 20px;
  -moz-border-radius   : 20px;
  -ms-border-radius    : 20px;
  -o-border-radius     : 20px;
  -webkit-transition   : all 0.3s ease;
  -moz-transition      : all 0.3s ease;
  -ms-transition       : all 0.3s ease;
  -o-transition        : all 0.3s ease;
}

.sidebar .navbar {
  height     : 90%;
  width      : 100%;
  display    : flex;
  align-items: center;
}

.sidebar .navbar ul {
  list-style     : none;
  display        : flex;
  justify-content: center;
  flex-direction : column;
  height         : 100%;
  overflow       : hidden;
  padding        : 0 !important;
  width          : 100%;
}

.sidebar .navbar ul li {
  font-size   : 17px;
  padding-left: 20px;
  font-weight : 600;
  margin      : 10px 0;
}

.sidebar .navbar ul li a {
  text-decoration          : none;
  line-height              : 30px;
  color                    : #b2b7d2;
  display                  : inline-block;
  width                    : 100%;
  padding                  : 6px 10px 6px 10px;
  border-top-left-radius   : 15px;
  border-bottom-left-radius: 15px;
  display                  : flex;
  align-items              : center;
}

.sidebar .navbar ul li a span svg {
  width : 100%;
  height: 100%;
}

.sidebar .navbar ul li a span {
  margin-right   : 10px;
  display        : flex;
  align-content  : center;
  justify-content: center;
  width          : 27px;

}

.sidebar .navbar ul li a.activelink {
  color           : #3c4572;
  background-color: #e9ecfb;
}

.sidebar .navbar ul li a.activelink span svg path {
  fill: #3c4572 !important;
}

.sidebar .navbar ul li a img {
  width       : 25px;
  height      : 30px;
  position    : relative;
  top         : 5px;
  margin-right: 5px;
}

.mobile-menu svg {
  position: relative;
  top     : 10px;
  left    : 10px;
  width   : 25px;
  height  : 25px;
  z-index : 3;
}

@media (min-width: 768px) {
  .sidebar {
    width       : 25%;
    margin-right: 20px;
    max-width   : 300px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 80%;
    align-self: center;
    margin : 0;
  
    padding: 28px 0 30px 0px;
  }
}
.methode-container *{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.methode-container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.methode-container .methode-box{
  
}
.chats {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.chats .chat-item {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 1px 2px 19px 0px #00000052;
  border-radius: 17px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 19px;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 13px;
}
.chats .chat-item p {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#ShowChats {
  width: 100%;
  height: 100%;
  transition: all 2s ease-in;
}

/* @media screen and (max-width: 992px) {
    .chats .chat-item p{
        max-width: 50%;
     
    }} */

.notifies {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: scroll;
}
.notifies .notify-item {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: inset 1px 2px 19px 0px #00000052;
  border-radius: 17px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 19px;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 13px;
  position: relative;
}
.notifies .notify-item p {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-item {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 300px !important;
  box-shadow: inset 1px 2px 19px 0px #00000052;
  border-radius: 17px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 25px;
  color: #4b5aa7c4;
  font-weight: 600;
  font-size: 14px;
  overflow-y: scroll;
  text-align: justify;
}

.aboutus {
  display: flex;
  justify-content: flex-end;
}

p.title.about {
  text-transform: uppercase;
}

.horizentalTitle {
  text-orientation: sideways;
  -webkit-text-orientation: sideways;
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
  color: #3c4572;
  font-weight: 900;
  opacity: 0.33;
  letter-spacing: 21px;
  font-size: 40px;
  line-height: 7px;
  margin-bottom: 20px;
  margin-right: -26px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.aboutContent {
  box-shadow: -14px -14px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #b0b5ce;
  border-radius: 50px 0 0 0;
  -webkit-border-radius: 50px 0 0 0;
  -moz-border-radius: 50px 0 0 0;
  -ms-border-radius: 50px 0 0 0;
  -o-border-radius: 50px 0 0 0;
  width: 70%;
  margin: 20px;
  padding: 17px 30px;
  margin-top: -15px;
}
.aboutContent p {
  line-height: 26px;
  text-shadow: #3c4572;
}
.aboutImage {
  margin-top: -50px;
  display: flex;
  margin-right: 20px;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.aboutImage img {
  width: 185px;
  object-fit: cover;
}
@media only screen and (max-width: 768px) {
  .aboutus {
    justify-content: center;
  }
  .aboutContent {
   
    padding: 17px 10px 17px 15px;
    margin-right: 0;
 
  }
}

.contactus {
  display: flex;
}

.contactus .info {
  padding              : 50px 50px;
  border-radius        : 30px 0 0 30px;
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius   : 30px 0 0 30px;
  -ms-border-radius    : 30px 0 0 30px;
  -o-border-radius     : 30px 0 0 30px;
  background-color     : #3c4572;
  color                : #dcdcdc;
  margin               : 0px 0px 70px 20px;
  flex                 : 1 1;
}

.contactus .info * {
  box-sizing: border-box;
}

.contactus .info h2 {
  margin-bottom: 45px;
}

.contactus .info p {
  letter-spacing: 1.72px;
  text-align    : left;
  line-height   : 1.67;
  font-weight   : 500;
  font-size     : 14px;
  margin-bottom : 30px;
  display       : flex;
}

.contactus .info p svg {
  margin-right: 10px;
  color       : #dcdcdc;
  fill        : #dcdcdc;
}

.mainContainer.next {
  display        : flex;
  justify-content: flex-end;
  align-self     : center;
}

.contents {
  display: flex;
}

.next-container {
  /* margin: auto 0; */
  width : 87%;
}

.next-container .caption {
  margin-right         : 0;
  padding              : 100px 70px 100px 100px;
  border-radius        : 30px 0 0 30px;
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius   : 30px 0 0 30px;
  -ms-border-radius    : 30px 0 0 30px;
  -o-border-radius     : 30px 0 0 30px;
  background-color     : #3c4572;
  color                : #dcdcdc;
}

.next-container .caption h1 {
  text-align   : center;
  margin-bottom: 30px;
}

.next-container .caption p {
  text-align: left;
}

.vertical-text {
  color         : #3c4572;
  font-weight   : 900;
  opacity       : 0.33;
  letter-spacing: 21px;
  font-size     : 40px;
  line-height   : 7px;
  text-align    : left;
}

.contactus .info h1 {
  text-align   : center;
  font-size    : 20px;
  margin-bottom: 40px;
}

.contactus .info .caption {
  font-weight: 700;
  line-height: 30px;
  max-width  : 760px;
}

.contactus .form {
  flex    : 1.5 1;
  padding : 30px 20px 30px 70px;
  position: relative;
}

.contactus .form>p {
  letter-spacing: 2.13px;
  text-align    : left;
  color         : #181d37;
  font-weight   : 900;
  max-width     : 400px;
  font-size     : 14px;
  line-height   : 27px;
}

.form * {
  box-sizing: border-box;
}

.form-Item {
  position     : relative;
  width        : 70%;
  height       : 50px;
  overflow     : hidden;
  margin-bottom: 20px;
  max-width    : 400px;
}

.form-Item input {
  position   : absolute;
  width      : 100%;
  height     : 100%;
  padding-top: 20px;
  outline    : none;
  border     : none;
  color      : #181d375f;
  background : transparent;
  font-size  : 15px;
  font-weight: 600;
}

.form-Item label {
  position      : absolute;
  left          : 0px;
  border-bottom : 1px solid #181d375f;
  bottom        : 0px;
  width         : 100%;
  height        : 100%;
  pointer-events: none;
  color         : #181d375f;
}

.form-Item label::after {
  content           : "";
  position          : absolute;
  left              : 0px;
  bottom            : 0px;
  width             : 100%;
  height            : 100%;
  border-bottom     : 3px solid #3c4572;
  transform         : translateX(-100%);
  transition        : all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition   : all 0.3s ease;
  -ms-transition    : all 0.3s ease;
  -o-transition     : all 0.3s ease;
  -webkit-transform : translateX(-100%);
  -moz-transform    : translateX(-100%);
  -ms-transform     : translateX(-100%);
  -o-transform      : translateX(-100%);
}

.form-Item .content-name {
  position          : absolute;
  left              : 0px;
  bottom            : -28px;
  width             : 100%;
  height            : 100%;
  transition        : all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition   : all 0.3s ease;
  -ms-transition    : all 0.3s ease;
  -o-transition     : all 0.3s ease;
}

.form-Item input:focus+.label-name .content-name,
.form-Item input:valid+.label-name .content-name {
  transform        : translateY(-50%);
  font-size        : 14px;
  color            : #3c4572;
  font-weight      : 600;
  -webkit-transform: translateY(-50%);
  -moz-transform   : translateY(-50%);
  -ms-transform    : translateY(-50%);
  -o-transform     : translateY(-50%);
}

.form-Item input:focus+.label-name::after,
.form-Item input:focus+.label-name::after {
  transform        : translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform   : translateX(0%);
  -ms-transform    : translateX(0%);
  -o-transform     : translateX(0%);
}

.horizentalTitle.contact {
  position: absolute;
  bottom  : -30px;
  left    : 80%;
}

.contact-btn {
  outline         : none;
  color           : var(--button-color-2);
  background-color: var(--primery-color);
  padding         : 10px 30px;
  outline         : none;
  border          : none;
  font-weight     : 600;
  font-size       : 17px;
  letter-spacing  : 1px;
  width           : 50%;
  max-width       : 350px;
  margin-top      : 30px;
  margin-bottom   : 30px;
  cursor          : pointer;
}

.contents {
  position: relative;
}

.social-media {
  position       : absolute;
  bottom         : 20px;
  right          : 20px;
  max-width      : 400px;
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

.social-media a {
  cursor: pointer;
}

.social-media svg {
  fill     : #3c45726c!important;
  font-size: 25px;
  margin   : 0 15px;

}

.social-media svg:last-child {
  margin-right: 0;
}

@media screen and (max-width: 992px) {
  .contactus {
    flex-direction: column;
  }

  .contactus .info {
    padding: 30px 30px;
  }

  .horizentalTitle.contact {
    bottom: 60px;
    left  : 80%;
  }

  .social-media {
    bottom   : 20px;
    right    : 40px;
    max-width: 300px;
  }
}

@media screen and (max-width: 786px) {

  .mainContainer.contact,
  .mainContainer.next {
    position: relative;
    overflow: hidden;
  }

  .mainContainer.next {
    min-height: 100vh;
    display: flex;
  }

  .next-container {
    align-self: center;
    margin-bottom: 55px;
    margin-top: 55px;
  }

  .next-container .caption {
    margin-right: 0;
    padding     : 50px 70px 50px 50px;
  }

  .form-Item {

    width: 80%;

  }
  .contact-btn {
  
    margin-bottom: 20px;

}
.contactus .info {

  margin: 20px 0px 70px 20px;

}
}

@media screen and (max-width: 425px) {
  .contactus .form {
 
    padding : 30px 20px 30px 24px;
  
  }
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  cursor: default;
}
p.title {
  font-size: 40px;
  color: #3c4572;
  font-weight: 600;
  margin: 20px;
  align-self: baseline;
}
.mainContainer {
  flex-basis: 100%;

}
.mainContent {
  border-radius: 20px;
  background-color: #d1d6ef;
  padding: 36px 20px;
  box-shadow: -7px -7px 12px 0 rgba(0, 0, 0, 0.16);
  margin-right: 20px;
}
.mainContent > div {
  overflow-x: scroll;
}

.title-header,
.body-content {
  display: flex;
  justify-content: space-around;
}
.body-content {
  background-color: #e9ecfb;
  height: 40px;
  border-radius: 10px;
  line-height: 40px;
  margin: 10px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.body-content span {
  color: #3c4572;
}
.body-content span a {
  color: #3c4572;
  text-decoration: none;
}
.body-content span,
.title-header span {
  text-align: left;
  width: 27%;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.not-gen {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-top: 50px;
}
.title-header span:first-child,
.body-content span:first-child {
  text-indent: 20px;
  width: 10%;
}
.title-header span:not(:first-child) {
  color: #7b7b7b;
}
.title-header span:first-child {
  color: #3c4572;
}
.body-content span:last-child,
.title-header span:last-child {
  width: 10%;
}
.body-content span svg {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: red !important;
  position: relative;
  cursor: pointer;
  top: 10px;
}
.textContainer {
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 20px 0;
  box-sizing: border-box;
  width: calc(100% - 20px);
  right: 0px;
}
.textContainer .caption {
  border-radius: 22px;
  /*box-shadow: -7px -7px 12px 0 rgb(0 0 0 / 16%);
  */
  padding: 1px 112px 1px 20px;
  background-color: #d1d6ef;
  line-height: 30px;
  border-radius: 22px;
  box-shadow: -14px -14px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #d1d6ef;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  -ms-border-radius: 22px;
  -o-border-radius: 22px;
}
.textContainer .caption p,
.textContainer .caption span {
  color: #3c4572;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.98px;
}
.textContainer .caption p {
}
.textContainer .circleTitle {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: #b0b5ce;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.textContainer .circleTitle p {
  white-space: nowrap;
  line-height: 0px;
  color: #3c4572;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.textContainer .circleTitle p:first-child {
  font-size: 22px;
  font-weight: 600;
  margin-top: 4px;
}
.textContainer .circleTitle p:last-child {
  margin-top: 2px;
}
@media screen and (max-width: 786px) {
  .mainContent {
    margin: 0 auto !important;
    max-width: 90vw;
  }
  .mainContent.wallet {
    margin-top: 70px !important;
    margin-bottom: 30px !important;
  }
  p.title {
    margin-top: 60px;
  }
  .textContainer {
    margin-left: 20px;
    width: calc(100% - 40px);
  }
  .body-content span a {
    margin-right: 50px;
  }
  .title-header span:nth-child(4),
  .body-content span:nth-child(4) {
    position: relative;
    left: 20px;
  }
  .body-content span svg {
    left: 10px;
  }
}
@media screen and (max-width: 760px) {
  .tbl-scroll {
    min-width: 700px;
  }
}
.center-container {
    width: 100%;
    margin-right: 30px;
}
.documents {
    background-color: #d1d6ef;
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
}
@media screen and (max-width: 770px) {
    .documents-res {
        margin-left: 20px;
        width: calc(100% - 10px);
    }
}
.document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    color: #4b5aa7;
}
.document img {
    width: 200px;
    height: 200px;
    border-radius: 15px;
    object-fit: cover;
    box-shadow: 0 5px 15px #8e93aa;
}
.closebtn-wallet svg {
  font-size: 15px;
  fill: white !important;
}
.closebtn-wallet {
  position: absolute;
  top: 16px;
  left: 0px;
  background-color: #5162bb;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white !important;
  height: 20px;
  border-radius: 0 8px 8px 0;
  transition: all 0.4s ease;
  -webkit-transition: ;
  -moz-transition: ;
  -ms-transition: ;
  -o-transition: ;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  -ms-border-radius: 0 8px 8px 0;
  -o-border-radius: 0 8px 8px 0;
  cursor: pointer;
}
.closebtn-wallet::after {
  content: "";
  position: absolute;
  width: 16.4px;
  height: 16.6px;
  left: -6px;
  top: 1px;
  background-color: #5162bb;
  border-radius: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.mainButton {
  outline: none;
  color: var(--button-color-2);
  background-color: var(--primery-color);
  border-radius: 38px;
  padding: 10px 30px;
  outline: none;
  border: none;
  -webkit-border-radius: 38px;
  -moz-border-radius: 38px;
  -ms-border-radius: 38px;
  -o-border-radius: 38px;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.add-plan {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  background-color: #d1d6ef;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.add-plan p {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: black solid 1px;
  font-weight: 600;
  font-size: 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.finance {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    background-color: #d1d6ef;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.finance .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.finance .title h4 {
    flex-grow: 0;
    color: #9a9a9a;
    font-size: 16px;
    font-weight: 900;
}

.finance .commissions {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-template-rows: auto;
    grid-gap: 10px;
    margin: 0 auto;
}

.finance .commissions button {
    width: 100%;
    cursor: pointer;
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.finance .commissions button .percent {
    border-radius: 50%;
    display: flex;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 900;
    color: var(--button-color);
    background-color: var(--button-color-3);
    margin: 0 auto;
    box-shadow: 0 4px 15px #525d97;
    margin-bottom: 24px;
}

.finance .commissions button span {
    display: block;
    font-size: 16px;
    font-weight: 900;
    color: var(--secondry-color);
    margin: auto 0;
    text-align: center;
    word-break: break-all;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.actions button {
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 900;
    background-color: var(--secondry-color);
    color: var(--button-color);
    margin: 0 4px;
    border-radius: 10px;
}

.actions button:first-child {
    height: 48px;
    margin-left: 0;
}

.actions button:last-child {
    height: 40px;
    margin-right: 0;
}

.upgrade {
    margin: 4px 0 0 0;
    width: 100%;
    text-align: end;
}

.upgrade button {
    background-color: transparent;
    border: none;
    color: #9a9a9a;
    font-size: 14px;
    font-weight: 900;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin: 16px 0 48px 0;
}

.input-group label {
    display: flex;
    justify-content: start;
    align-items: center;
}

.input-group label span {
    display: block;
    margin: 0 36px 0 8px;
    font-size: 16px;
    font-weight: 900;
    color: var(--primery-color);
}

.input-group label>button {
	border-radius: 50%;
	background-color: #5162bb;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	border: none;
	width: 20px;
	height: 20px;
	box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
}

.input-group .input-icon {
    margin: 12px 0 0 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-group .input-icon input, .input-group .input-icon span {
    max-width: 100%;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid var(--primery-color);
    color: var(--primery-color);
    font-size: 18px;
}

.input-group .input-icon span {
    width: 36px;
    height: 38px;
}

.input-group .input-icon input {
    height: 36px;
    width: calc(100% - 36px);
}

.color-gold {
    color: #CFB53B !important;
}

.font-sm {
    font-size: 12px;
    margin: 0;
}
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.credit-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 4px 32px 12px;
  background-color: #d1d6ef;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 20px !important;
}

.credit-cards .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.credit-cards .title h4 {
  flex-grow: 0;
  color: #9a9a9a;
  font-size: 16px;
  font-weight: 900;
}

.credit-cards .title .arrows {
  display: flex;
  flex-grow: 0;
}

.credit-cards .title .arrows button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  border-radius: 50%;
  background-color: transparent;
  color: var(--primery-color);
  border: 1px solid var(--primery-color);
}

.credit-cards .title .arrows button:first-child {
  margin-left: 0;
}

.credit-cards .title .arrows button:last-child {
  margin-right: 0;
}

.credit-cards .images {
  text-align: center;
  margin: 0 0 16px 0;
}

.credit-cards .images img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

.credit-cards .images img.d-none {
  display: none;
}

.credit-cards .dots ul {
  margin: 0;
  padding: 0;
}

.credit-cards .dots ul > li {
  position: relative;
  display: inline-block;
  margin: 0 7px;
}
.credit-cards .dots ul > li:first-child {
  margin-left: 0;
}
.credit-cards .dots ul > li:last-child {
  margin-right: 0;
}
.credit-cards .dots ul > li button {
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #9a9a9a;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.history-title {
  position: relative;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 17px 20px 14px 0 rgba(81, 98, 187, 0.16);
  background-color: #5162bb;
  color: #f5f5f5;
  margin: 20px 20px 20px 0;
  margin-left: 45px;
  align-self: flex-start;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.history-title::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  bottom: -10px;
  left: 100px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  background-color: #5162bb;
}

.history-title p {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 2px;
}

.history-title p span {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.history-title p span svg {
  width: 100%;
  height: 100%;
}

.transaction-container {
  height: 320px;
  overflow-y: scroll;
}
.icon {
  height: 70%;
  width: 50px;
  margin-right: 10px;
  border-radius: 12px;
  box-shadow: 9px 6px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
}
.hash {
  word-break: break-word !important;
}

@media screen and (max-width: 560px) {
   .transaction-container {
    height: 320px;
    overflow-y: scroll;
    /*max-width: 350px;*/
  }
  .trnasaction-main {
    /*margin-left: -35px;*/
    width: 100% !important;
  }
  .history-title {
    margin-left: 15px;
  }
  .trxrow {
    max-width: 300px;
    /*margin-left: -20px;*/
  }
  .trxrow.transction {
    max-width: 300px;
    /*overflow: hidden;*/
  }

  .icon {
    /*height: 30%;*/
    border-radius: 6px;
  }
  .amount {
    margin-left: -20px;
    position: absolute;
    left: 200px;
    top: -10px;
  }
  .sendrec {
    font-size: 10px;
  }
  .hash {
    font-size: 5px;
    word-break: break-word !important;
  }
}

@media screen and (max-width: 428px) {
  .wallet-manage .actions {
    flex: 3 1;
  }

  .wallet-manage .content {
    flex: 2 1;
  }

  .wallet-manage .actions .resive,
  .wallet-manage .actions .send {
    width: 100%;
    padding: 10px 0;
  }

  /* .icon {
    height: 50%;
    width: 30px;
    font-size: 30px;
    line-height: 30px;
    border-radius: 6px;
  } */
}

.pakage {
  box-shadow: 7px 5px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #e9e9e9;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 15px;
  overflow: hidden;
  margin: 13px;
}
.pakage .header {
  position: relative;
  width: 100%;
  height: 200px;
}
.pakage .header .top {
  position: absolute;
  font-size: 25px;
}
.pakage .header .top * {
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.pakage .header .top h3 {
}
.pakage .header .top h2 {
  letter-spacing: 3px;
  font-size: 30px;
  font-weight: bolder;
  position: relative;
}
.pakage .header .top h2 span {
  position: absolute;
  top: 10px;
  padding-left: 5px;
  font-size: 14px;
  letter-spacing: 0px;
}
.pakage .header .top .cut {
}
.pakage .header .buttom {
  position: absolute;
  width: 80px;
  height: 100px;
  right: 0px;
  bottom: 20px;
}
.pakage .header .buttom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pakage .header:before {
  z-index: 0;
  content: "";
  position: absolute;
  width: 105%;
  height: 148%;
  bottom: 36%;
  left: -55px;
  /* background-color: rgb(245, 200, 105); */
  transform: rotate(59deg);
  -webkit-transform: rotate(59deg);
  -moz-transform: rotate(59deg);
  -ms-transform: rotate(59deg);
  -o-transform: rotate(59deg);
}
.pakage .divider {
  height: 3px;
  width: 90%;

  margin: 0 auto;
}
.pakage .content h5 {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0;
  margin: 5px;
  font-weight: 600;
}
.pakage .content h5:last-child {
  padding-left: 25px;
}
.pakage .content h5 span {
  color: #1db800 !important;
  font-size: 14px;
  margin-left: 8px;
}
.pakage .content {
  margin: 30px auto;
}
.pakage .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pakage .btn button {
  padding-right: 55px !important ;
  padding-left: 55px !important;
}

.recive-manager {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.6);
  width: 85%;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.recive-manager > div {
  margin-top: 15px;
}
.recive-manager img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center;
}

.recive-manager .code {
  border-radius: 16px;
  box-shadow: -3px 3px 10px 0 #645d5d;
  background-color: #d1d6ef;
  box-sizing: border-box;
  padding: 5px 10px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
.recive-manager .code p {
  max-width: 100%;
  text-align: center;

  text-align: right;
  color: #5162bb;
  font-size: 13px;
  font-weight: 600;
  min-width: 80%;
}
.copy-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.copy-container svg {
  background-color: #5162bb;
  border-radius: 30px;
}
.copy-container svg path {
  fill: #d1d6ef !important;
}
.copy-container p {
  color: #5162bb;
  margin-top: 5px;
}
@media screen and (max-width: 560px) {
  .recive-manager {
    width: 100%;
  }
  .recive-manager .code p {
    font-size: 9px;
  }
}

.send-manage {
  position: relative;
  border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.9);
  width: 85%;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 45px;
  box-sizing: border-box;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.send-manage * {
  box-sizing: border-box;
}

.sendbtn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  padding-left: 30px;
}

.send-manage .content {
  width: 100%;
  width: 100%;
  /* min-width  : 270px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.send-manage .content h3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.send-manage .content h3 span {
  font-size: 12px;
  color: #8b93bb;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.send-manage .content h3 span img {
  width: 17px;
}

.send-manage .content .item-container {
  display: flex;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  font-weight: 600;
}

.send-manage .content .item-container .item1 label {
  color: #8b93bb !important;
}
.send-manage .content .item-container .item1 {
  width: 100%;
  border-radius: 16px;
  box-shadow: -3px 3px 10px 0 #645d5d;
  background-color: #4b5aa7;
  padding: 10px;
  box-sizing: border-box;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  display: grid;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  grid-template-rows: auto;
}
.send-manage .content .item-container:nth-child(even) .item1 {
  background-color: #d0d6ee;
  color: #4b5aa7;
}
.send-manage .content .item-container .item1 p,
.send-manage .content .item-container .item2 p {
  margin: 0;
}

.send-manage .content .item-container input {
  width: 100%;
  padding: 3px;
  background-color: transparent;
  border: none;
  color: #8b93bb;
}
#address input::-webkit-input-placeholder {
  color: inherit !important;
}
#address input:-ms-input-placeholder {
  color: inherit !important;
}
#address input::placeholder {
  color: inherit !important;
}

.send-manage .content .item-container input::-webkit-input-placeholder {
  color: #d1d6ef;
}

.send-manage .content .item-container input:-ms-input-placeholder {
  color: #d1d6ef;
}

.send-manage .content .item-container input::placeholder {
  color: #d1d6ef;
}

.send-manage .content .item-container .item2 {
  border-radius: 16px;
  box-shadow: -3px 3px 10px 0 #645d5d;
  background-color: #d1d6ef;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

#free {
  font-size: 12px;
  background-color: #d1d6ef;
  color: #5162bb;
}

#netFee {
  font-size: 12px;
  color: #b2b7d283;
}

#memo {
  font-size: 12px;
  color: #b2b7d283;
  background-color: #5162bb;
}
#memo input {
  width: 80%;
}
#cyrus {
  width: calc(100% - 200px);
  font-size: 12px;
  color: #b2b7d283;
  background-color: #5162bb;
  margin-left: 60px;
  box-shadow: -3px 3px 20px 0 #ffffff;
}

#usd {
  right: 50px;
  width: calc(90% - 260px);
  font-size: 12px;
  color: #5163bb7a;
  background-color: #d1d6ef;
}

#memo,
#free {
  width: calc(92% - 260px);
  right: 0px;
}

#netFee {
  width: calc(100% - 160px);
  margin-left: 40px;
}

@media screen and (max-width: 1252px) {
  #cyrus {
    width: calc(100% - 200px);
    font-size: 12px;
    color: #b2b7d283;
    background-color: #5162bb;
    margin-left: 60px;
    box-shadow: -3px 3px 20px 0 #ffffff;
  }

  #usd {
    right: 50px;
    width: calc(100% - 255px);
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(100% - 200px);
    right: 0px;
  }

  #netFee {
    width: calc(100% - 100px);
    margin-left: 40px;
  }
}

@media screen and (max-width: 1192px) {
  #cyrus {
    width: calc(100% - 146px);
    font-size: 12px;
    color: #b2b7d283;
    background-color: #5162bb;
    margin-left: 60px;
    box-shadow: -3px 3px 20px 0 #ffffff;
  }

  #usd {
    right: 50px;
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(90% - 160px);
    right: 0px;
  }

  #netFee {
    width: calc(100% - 160px);
    margin-left: 40px;
  }
}

@media screen and (max-width: 560px) {
  .send-manage {
    width: 100%;
  }
  #cyrus {
    width: calc(100% - 35px);
    font-size: 12px;
    margin-left: 0px;
  }

  #usd {
    right: -17px;
    width: calc(100% - 135px);
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(100% - 76px);
    right: -35px;
  }

  #netFee {
    width: calc(100% - 0px);
    margin-left: -10px;
  }
}

.send-manage {
  position: relative;
  border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.9);
  width: 85%;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 45px;
  box-sizing: border-box;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.send-manage * {
  box-sizing: border-box;
}
.sendbtn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
}

.send-manage .content {
  width: 100%;
  width: 100%;
  /* min-width  : 270px; */
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}
.send-manage .content h3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.send-manage .content h3 span {
  font-size: 12px;
  color: #8b93bb;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.send-manage .content h3 span img {
  width: 17px;
}

.send-manage .content .item-container {
  display: flex;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  font-weight: 600;
}

.send-manage .content .item-container .item1 {
  width: 100%;
  border-radius: 16px;
  box-shadow: -3px 3px 10px 0 #645d5d;
  background-color: #4b5aa7;
  padding: 10px;
  box-sizing: border-box;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  display: grid;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  grid-template-rows: auto;
}
.send-manage .content .item-container .item1 label {
  color: #8b93bb !important;
}
.send-manage .content .item-container:nth-child(even) .item1 {
  background-color: #d0d6ee;
  color: #4b5aa7;
}
.send-manage .content .item-container .item1 p,
.send-manage .content .item-container .item2 p {
  margin: 0;
}

.send-manage .content .item-container input {
  width: 100%;
  padding: 3px;
  background-color: transparent;
  border: none;
  color: #8b93bb;
}
#address input::-webkit-input-placeholder {
  color: inherit !important;
}
#address input:-ms-input-placeholder {
  color: inherit !important;
}
#address input::placeholder {
  color: inherit !important;
}

.send-manage .content .item-container input::-webkit-input-placeholder {
  color: #d1d6ef;
}

.send-manage .content .item-container input:-ms-input-placeholder {
  color: #d1d6ef;
}

.send-manage .content .item-container input::placeholder {
  color: #d1d6ef;
}

.send-manage .content .item-container .item2 {
  border-radius: 16px;
  box-shadow: -3px 3px 10px 0 #645d5d;
  background-color: #d1d6ef;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

#free {
  font-size: 12px;
  background-color: #d1d6ef;
  color: #5162bb;
}

#netFee {
  font-size: 12px;
  color: #b2b7d283;
}

#memo {
  font-size: 12px;
  color: #b2b7d283;
  background-color: #5162bb;
}
#memo input {
  width: 80%;
}

#cyrus {
  width: calc(100% - 200px);
  font-size: 12px;
  color: #b2b7d283;
  background-color: #5162bb;
  margin-left: 60px;
  box-shadow: -3px 3px 20px 0 #ffffff;
}

#usd {
  right: 50px;
  width: calc(90% - 260px);
  font-size: 12px;
  color: #5163bb7a;
  background-color: #d1d6ef;
}

#memo,
#free {
  width: calc(92% - 260px);
  right: 0px;
}

#netFee {
  width: calc(100% - 160px);
  margin-left: 40px;
}

@media screen and (max-width: 1252px) {
  #cyrus {
    width: calc(100% - 200px);
    font-size: 12px;
    color: #b2b7d283;
    background-color: #5162bb;
    margin-left: 60px;
    box-shadow: -3px 3px 20px 0 #ffffff;
  }

  #usd {
    right: 50px;
    width: calc(100% - 255px);
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(100% - 200px);
    right: 0px;
  }

  #netFee {
    width: calc(100% - 100px);
    margin-left: 40px;
  }
}

@media screen and (max-width: 1192px) {
  #cyrus {
    width: calc(100% - 146px);
    font-size: 12px;
    color: #b2b7d283;
    background-color: #5162bb;
    margin-left: 60px;
    box-shadow: -3px 3px 20px 0 #ffffff;
  }

  #usd {
    right: 50px;
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(90% - 160px);
    right: 0px;
  }

  #netFee {
    width: calc(100% - 160px);
    margin-left: 40px;
  }
}

@media screen and (max-width: 560px) {
  .send-manage {
    width: 100%;
  }
  #cyrus {
    width: calc(100% - 35px);
    font-size: 12px;
    margin-left: 0px;
  }

  #usd {
    right: -17px;
    width: calc(100% - 135px);
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(100% - 76px);
    right: -35px;
  }

  #netFee {
    width: calc(100% - 0px);
    margin-left: -10px;
  }
}
.item-style {
  padding: 10px !important;
  display: flex !important;
  width: 100%;
}
.item-style input {
  width: 50px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

}
.mainContent.wallet {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.leftButton {
  position: absolute;
  left: -20px;
  bottom: -10px;
  color: #e9e9e9 !important;
  font-size: 16px;
}
button {
  cursor: pointer;
}


.mainContainer.wallet * {

  /* box-sizing: border-box; */
}
.mainContainer.wallet {
  display      : flex;
  margin-left  : -20px;
  margin-top   : 20px;
  margin-bottom: 20px;

}
.wallet .right-container {
  margin-left   : 10px;
  width         : 35%;
  display       : flex;
  justify-content: space-between;
  align-items   : center;
  flex-direction: column;
  height        : 100%;
}

.wallet .center-container {
  margin-right  : 10px;
  width         : 65%;
  display       : flex;
  align-items   : center;
  flex-direction: column;
  height        : 100%;
}
p.wallet-title {
  font-size: 30px;
  color: black;
  font-weight: 600;
  margin: 20px;
  align-self: baseline;
}
@media screen and (max-width: 992px) {
  .mainContainer.wallet {
      flex-direction: column;
  }

  .wallet .right-container,
  .wallet .center-container {
      width: 100%;
  }

  .wallet .right-container {
      margin-left: 0px;
  }

  .wallet .center-container {
      margin-right: 0px;
  }

}

@media screen and (max-width: 768px) {
  .mainContainer.wallet {
      margin-top   : 65px;
      margin-left  : 0px;
      margin-right : 0px;
      box-sizing   : border-box;
      padding-left : 1.25rem;
      padding-right: 1.25rem;
      width: 100vw;
  }
}

@media screen and (max-width: 1094px) {
  .mainContent.wallet {

    justify-content: center;

  }

}

.wallet-manage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.6);
  /*width: 85%;*/
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 40px 20px;
  box-sizing: border-box;
  transition: all 0.4s ease;
  /*height: 250px;
     */
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.wallet-manage .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 0 0 25px;
}

.wallet-manage .content * {
  margin: 0;
}

.wallet-manage .content .head {
  letter-spacing: 0.67px;
  text-align: left;
  font-size: 8px;
  color: #9a9a9a;
  width: 180px;
  margin-bottom: 5px;
}

.wallet-manage .content .add {
  position: relative;
}

.wallet-manage .content .add > button {
  position: absolute;
  bottom: 100%;
  left: 80%;
  border-radius: 50%;
  background-color: #5162bb;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border: none;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
}

.wallet-manage .content .add .balance {
  letter-spacing: 2.56px;
  text-align: left;
  color: #000000;
  border-bottom: solid 1px #707070;
  width: 180px;
  margin-bottom: 10px;
  font-size: 22px;
}

.wallet-manage .content .saving {
  letter-spacing: 0.81px;
  text-align: left;
  color: #3a3939;
  font-size: 10px;
  width: 180px;
  margin-top: 15px;
}

.wallet-manage .actions {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.wallet-manage .actions .resive,
.wallet-manage .actions .send {
  padding: 16px 64px;
  font-size: 16px;
  border: none;
  font-weight: 600;
  margin: 20px;
}

.wallet-manage .actions .send {
  border-radius: 20px;
  width: 50%;
  background-color: #5162bb;
  color: #fffbfb;
  box-sizing: border-box;
}

.wallet-manage .actions .resive {
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.479);
}

@media screen and (max-width: 500px) {
  .wallet-manage {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .wallet-manage .content {
    padding: 0;
  }
  .wallet-manage .actions .resive,
  .wallet-manage .actions .send {
    width: 80%;
    padding: 10px 0;
    margin: 1.25rem 0;
  }
}

@media screen and (max-width: 411px) {
  .wallet-manage .actions {
    flex: 1 1;
  }

  .wallet-manage .content {
    flex: 2 1;
  }
  .wallet-manage .actions .resive,
  .wallet-manage .actions .send {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .wallet-manage {
    flex-direction: column;
    padding-bottom: 0;
  }
  .wallet-manage .actions {
    width: 100%;
  }
}

.add-wallet {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  width: 100%;
  height: 100%;
}

.add-wallet .item {
  cursor: pointer;
  display: flex;
  width: calc(50% - 2px);
  height: calc(50% - 2px);
  align-items: center;
  justify-content: center;
}

.add-wallet .item span {
  display: block;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: var(--button-color-2);
  transition: all 250ms;
  text-align: center;
}

.add-wallet .item span.disabled {
  color: var(--box-color);
}

.add-wallet .item:hover span {
  color: var(--secondry-color);
}

.add-wallet .item:nth-child(1),
.add-wallet .item:nth-child(2) {
  border-bottom: 2px solid #b2b7d2;
}

.add-wallet .item:nth-child(1),
.add-wallet .item:nth-child(3) {
  border-right: 2px solid #b2b7d2;
}

.add-wallet .back {
  position: absolute;
  color: #b2b7d2;
  top: 20px;
  left: 0;
  border: none;
  background-color: transparent;
}

.mobile-add-wallet-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  position: relative;
}

.mobile-item {
  cursor: pointer;
  display: flex;
  width: calc(100vw - 100px);
  height: 300px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 5px;
  border: 2px solid #b2b7d28c;
  border-radius: 10px;
}

.mobile-item span {
  display: block;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: var(--button-color-2);
  transition: all 250ms;
  text-align: center;
}
.mobile-item span.disabled {
  color: var(--box-color) !important;
}

.transfer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  padding: 20px 0;
  width: 100%;
  height: 100%;
}

.transfer .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--primery-color);
  border-radius: 24px;
  width: 80% !important;
  height: 70%;
  padding: 20px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .transfer .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--primery-color);
    border-radius: 24px;
    width: 100% !important;
    height: 70%;
    padding: 20px;
    margin-top: 30px;
  }
}

.transfer .box .btn-group {
  margin: 80px 0 0 0;
  display: flex;
}

.transfer .box .btn-group button {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--button-color-3);
  width: 175px;
  font-weight: bold;
  border: none;
  height: 54px;
  border-radius: 12px;
  font-size: 16px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
}

.transfer .box .input-group {
  margin: 0 0 32px 0;
  display: flex;
  flex-direction: column;
}

.transfer .box .input-group:last-child {
  margin-bottom: 0;
}

.transfer .box .input-group label {
  display: block;
  padding: 0 0 8px 8px;
  font-size: 14px;
  font-weight: bold;
  color: var(--box-color);
}

.transfer .box .input-group .content-box {
  position: relative;
}

.transfer .box .input-group .input-box {
  background-color: var(--back-color);
  border-radius: 10px;
  height: 80px;
  width: 400px;
  max-width: 100%;
  position: relative;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
  padding: 0 12px;
  z-index: 9;
}

.transfer .box .input-group .input-box input {
  height: 100%;
  max-height: 100%;
  font-size: 18px;
  font-weight: bold;
  width: calc(100% - 100px);
  border: none;
  background-color: transparent;
  color: #d1d6ef;
}

.transfer .box .input-group .input-box .select {
  display: flex;
  align-items: center;
  border: none;
}

.transfer .box .input-group .input-box .select svg {
  height: 36px;
  width: 36px;
}

.transfer .box .input-group .input-box .select span {
  font-size: 16px;
  font-weight: 900;
  margin: 0 0 0 4px;
  color: var(--button-color);
}

.transfer .box .input-group .content-box .tooltip {
  position: absolute;
  left: 8px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
  top: calc(100% - 6px);
  background-color: #ffdc4a;
  border-radius: 10px;
  padding: 8px;
  z-index: 1;
}

.transfer .box .input-group .content-box .tooltip span {
  color: var(--primery-color);
  font-size: 14px;
  font-weight: bold;
}

.transfer .box .input-group .input-box .select {
  position: absolute;
  border-radius: 10px;
  background-color: var(--secondry-color);
  width: 100px;
  height: 48px;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.transfer .box .confirm-step {
  width: 60%;
  max-width: 100%;
}

.transfer .box .confirm-step .desc {
  width: 80%;
  margin: 0 auto 50px auto;
}

.transfer .box .confirm-step .desc p {
  font-size: 16px;
  font-weight: 900;
  color: var(--button-color-2);
  line-height: 2;
}

.token {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.token .logo,
.token .copy {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.token .logo svg {
  height: 54px;
  width: 54px;
}

.token .token-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;
  height: auto;
  background-color: var(--back-color);
  border-radius: 10px;
  box-shadow: 0px 0px 14px 0 rgb(81 98 187 / 16%);
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  overflow-x: visible;
}

.token .token-box span {
  font-size: 16px;
  font-weight: 900;
  color: var(--button-color-3);
  word-break: break-all;
}

.token .copy button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: #fff;
  background-color: var(--button-color-3);
  border-radius: 14px;
  height: 54px;
  width: 64px;
}

.token .copy span,
.token .logo span {
  display: block;
  font-size: 16px;
  font-weight: 900;
  margin: 12px 0 0 0;
  color: var(--button-color);
  text-align: center;
}

.coin-select {
  background-color: #5162bb;
  padding: 0 5px;
  height: 33px;
  cursor: pointer;
  color: #ebecf1;
  border: none;
  width: 100%;
}

.coin-select:focus {
  outline: none !important;
}

.spinner-border {
  display: inline-block;
  padding: 10px;
  border: 4px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border;
  color: #f8f9fa;
}
@-webkit-keyframes spinner-border{
  to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
}
@keyframes spinner-border{
  to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
}

.copyright-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.copyright-container p {
  text-align: center;
  color: #ebd778;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .copyright-container {
    display: none !important;
  }
}

.verify-account-container {
  background-color: #343434;
  font-family: "Microsoft YaHei", sans-serif;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.bg-darks {
  background-color: #262626 !important;
}

.text-orange {
  color: #ebd788;
}

.p-r {
  position: relative;
}

.verify-email-input {
  height: 45px;
  background-color: transparent;
  color: #fff;
  border: 1px solid rgba(204, 204, 204, 0.37);
  border-radius: 40px;
  padding-left: 5px;
  font-size: 15px;
  text-align: center;
}
.verify-email-input:focus {
  outline: none;
}

.verify-email-btn {
  height: 45px;
  background-color: #ebd788;
  border: none;
  border-radius: 40px;
  width: 190px;
  position: relative;
  bottom: 10px;
}

.Winput:focus + .BorderBottom::before {
    width: 100%;
}

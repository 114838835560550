.add-wallet {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  width: 100%;
  height: 100%;
}

.add-wallet .item {
  cursor: pointer;
  display: flex;
  width: calc(50% - 2px);
  height: calc(50% - 2px);
  align-items: center;
  justify-content: center;
}

.add-wallet .item span {
  display: block;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: var(--button-color-2);
  transition: all 250ms;
  text-align: center;
}

.add-wallet .item span.disabled {
  color: var(--box-color);
}

.add-wallet .item:hover span {
  color: var(--secondry-color);
}

.add-wallet .item:nth-child(1),
.add-wallet .item:nth-child(2) {
  border-bottom: 2px solid #b2b7d2;
}

.add-wallet .item:nth-child(1),
.add-wallet .item:nth-child(3) {
  border-right: 2px solid #b2b7d2;
}

.add-wallet .back {
  position: absolute;
  color: #b2b7d2;
  top: 20px;
  left: 0;
  border: none;
  background-color: transparent;
}

.mobile-add-wallet-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  position: relative;
}

.mobile-item {
  cursor: pointer;
  display: flex;
  width: calc(100vw - 100px);
  height: 300px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 5px;
  border: 2px solid #b2b7d28c;
  border-radius: 10px;
}

.mobile-item span {
  display: block;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: var(--button-color-2);
  transition: all 250ms;
  text-align: center;
}
.mobile-item span.disabled {
  color: var(--box-color) !important;
}

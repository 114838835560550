.send-manage {
  position: relative;
  border-radius: 30px;
  background-color: rgba(194, 200, 232, 0.9);
  width: 85%;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 45px;
  box-sizing: border-box;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.send-manage * {
  box-sizing: border-box;
}
.sendbtn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
}

.send-manage .content {
  width: 100%;
  width: 100%;
  /* min-width  : 270px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}
.send-manage .content h3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.send-manage .content h3 span {
  font-size: 12px;
  color: #8b93bb;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.send-manage .content h3 span img {
  width: 17px;
}

.send-manage .content .item-container {
  display: flex;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  font-weight: 600;
}

.send-manage .content .item-container .item1 {
  width: 100%;
  border-radius: 16px;
  box-shadow: -3px 3px 10px 0 #645d5d;
  background-color: #4b5aa7;
  padding: 10px;
  box-sizing: border-box;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: auto;
}
.send-manage .content .item-container .item1 label {
  color: #8b93bb !important;
}
.send-manage .content .item-container:nth-child(even) .item1 {
  background-color: #d0d6ee;
  color: #4b5aa7;
}
.send-manage .content .item-container .item1 p,
.send-manage .content .item-container .item2 p {
  margin: 0;
}

.send-manage .content .item-container input {
  width: 100%;
  padding: 3px;
  background-color: transparent;
  border: none;
  color: #8b93bb;
}
#address input::placeholder {
  color: inherit !important;
}

.send-manage .content .item-container input::placeholder {
  color: #d1d6ef;
}

.send-manage .content .item-container .item2 {
  border-radius: 16px;
  box-shadow: -3px 3px 10px 0 #645d5d;
  background-color: #d1d6ef;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

#free {
  font-size: 12px;
  background-color: #d1d6ef;
  color: #5162bb;
}

#netFee {
  font-size: 12px;
  color: #b2b7d283;
}

#memo {
  font-size: 12px;
  color: #b2b7d283;
  background-color: #5162bb;
}
#memo input {
  width: 80%;
}

#cyrus {
  width: calc(100% - 200px);
  font-size: 12px;
  color: #b2b7d283;
  background-color: #5162bb;
  margin-left: 60px;
  box-shadow: -3px 3px 20px 0 #ffffff;
}

#usd {
  right: 50px;
  width: calc(90% - 260px);
  font-size: 12px;
  color: #5163bb7a;
  background-color: #d1d6ef;
}

#memo,
#free {
  width: calc(92% - 260px);
  right: 0px;
}

#netFee {
  width: calc(100% - 160px);
  margin-left: 40px;
}

@media screen and (max-width: 1252px) {
  #cyrus {
    width: calc(100% - 200px);
    font-size: 12px;
    color: #b2b7d283;
    background-color: #5162bb;
    margin-left: 60px;
    box-shadow: -3px 3px 20px 0 #ffffff;
  }

  #usd {
    right: 50px;
    width: calc(100% - 255px);
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(100% - 200px);
    right: 0px;
  }

  #netFee {
    width: calc(100% - 100px);
    margin-left: 40px;
  }
}

@media screen and (max-width: 1192px) {
  #cyrus {
    width: calc(100% - 146px);
    font-size: 12px;
    color: #b2b7d283;
    background-color: #5162bb;
    margin-left: 60px;
    box-shadow: -3px 3px 20px 0 #ffffff;
  }

  #usd {
    right: 50px;
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(90% - 160px);
    right: 0px;
  }

  #netFee {
    width: calc(100% - 160px);
    margin-left: 40px;
  }
}

@media screen and (max-width: 560px) {
  .send-manage {
    width: 100%;
  }
  #cyrus {
    width: calc(100% - 35px);
    font-size: 12px;
    margin-left: 0px;
  }

  #usd {
    right: -17px;
    width: calc(100% - 135px);
    font-size: 12px;
    color: #5163bb7a;
    background-color: #d1d6ef;
  }

  #memo,
  #free {
    width: calc(100% - 76px);
    right: -35px;
  }

  #netFee {
    width: calc(100% - 0px);
    margin-left: -10px;
  }
}
.item-style {
  padding: 10px !important;
  display: flex !important;
  width: 100%;
}
.item-style input {
  width: 50px !important;
}
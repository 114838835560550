.verify-account-container {
  background-color: #343434;
  font-family: "Microsoft YaHei", sans-serif;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.bg-darks {
  background-color: #262626 !important;
}

.text-orange {
  color: #ebd788;
}

.p-r {
  position: relative;
}

.verify-email-input {
  height: 45px;
  background-color: transparent;
  color: #fff;
  border: 1px solid rgba(204, 204, 204, 0.37);
  border-radius: 40px;
  padding-left: 5px;
  font-size: 15px;
  text-align: center;
}
.verify-email-input:focus {
  outline: none;
}

.verify-email-btn {
  height: 45px;
  background-color: #ebd788;
  border: none;
  border-radius: 40px;
  width: 190px;
  position: relative;
  bottom: 10px;
}
